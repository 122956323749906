(function ($) {
    "use strict";
    $.fn.fileUploadSettings = {
        baseConfig: {
            max_file_size: '10485760b',
            flash_swf_url: '/Scripts/pupload/plupload.flash.swf',
            silverlight_xap_url: '/Scripts/pupload/plupload.silverlight.xap',
            runtimes: 'html5,flash,html4',
            multipart: true
        },
        forbiddenRegex: new RegExp("^.*\\.(exe|dll|bat|vbs|js)$", "i")/* Non sensible à la casse*/,
        rejectFile : function(input) {
            return $.fn.fileUploadSettings.forbiddenRegex.test(input);
        }
    };
    $.fn.fileUploadNg = function (options) {
        // Create some defaults, extending them with any options that were provided
        var settings = $.extend({
            action: '/Affaire/UploadFile'
        }, options);

        return this.each(function () {
            var me = $(this),
                myGuid = plupload.guid(),
                idBtn = "btnUpload-" + myGuid,
                idContainer = 'objectContainer-' + myGuid,
                idDropZone = 'dropZone-' + myGuid,
                altUrl = me.data('upload-url'),
                myFileContainer = me.parents('.input').find('.etape-file-container'),
                myForm = me.parents('form');

            var idAffaire = myForm.find('input[type=hidden][name=IdAffaire]').val(); // --> EtapeFormCollection.KeyNameIdAffaire
            var idTranche = myForm.find('input[type=hidden][name=Tranche]').val(); // --> EtapeFormCollection.KeyNameTranche
            var idEntreprise = myForm.find('input[type=hidden][name=Entreprise]').val(); // --> EtapeFormCollection.KeyNameNumeroContrat
            var verificationToken = myForm.find('input[type=hidden][name="__RequestVerificationToken"]').val();

            var origElementData = me.data();

            var uploadBar = $('<span class="btn btnAjaxUpload">Rattacher des fichiers</span><span class="uploading"></span>');
            var uploadButton = uploadBar.first();

            uploadButton.attr('id', idBtn);

            var objectContainer = me.parents('div:first');
            objectContainer.attr('id', idContainer);

            var dropZone = me.closest('.file-cell');
            dropZone.attr('id', idDropZone).addClass('dropzone');

            me.after(uploadBar);

            if (me.data('is-aloe') === true) {
                var aloeBar = $('<span style="margin-left:20px;display:inline-block;vertical-align:top;"><a class="btn primary" target="_blank" rel="noopener" href="https://aloe.enedis.fr/aloe.html" style="padding:2px 12px;"><span style="display:inline-block;vertical-align:middle;">Vérifier la conformité du CSV</span><img style="display:inline-block; height:18px; vertical-align:middle;margin-left:3px; background:#fff;" src="/Content/assets/aloe-couleurs.png" alt=""/></a><a data-content="ALOÉ est un outil web permettant de contrôler facilement la conformité des fichiers CSV déposés dans le cadre d\'un dossier cartographique PGOC" data-original-title="Aide" data-placement="above" data-toggle="popover" href="#" onclick="javascript:return false;" rel="popover" title=""><img src="/Content/assets/fugue/question.png" alt="?" style="display:inline-block;vertical-align:middle;"></a><p><small><em>(Uniquement depuis Firefox)</em></small></p><span>');

                uploadBar.last().after(aloeBar);
                uploadBar.css('vertical-align', 'top');
            }

            me.remove();

            var multipartData = {
                idChampsAttendu: origElementData['champsAttendu'],
                idAffaire: idAffaire,
                idTranche: idTranche,
                idEntreprise: idEntreprise,
                '__RequestVerificationToken': verificationToken
            };

            var cfg = $.extend($.fn.fileUploadSettings.baseConfig, {
                url: settings.action,
                container: idContainer,
                multipart_params: multipartData,
                browse_button: idBtn,
                drop_element: idDropZone
            });

            if (altUrl != null && altUrl != '') {
                cfg.url = altUrl;
                multipartData.AUTHK = myForm.find('input[type=hidden][name=AUTHK]').val();
            }

            var uploader = new plupload.Uploader(cfg);

            objectContainer.data('uploader', uploader);

            var toggleUploadButton = function (enable) {
                if (enable) {
                    uploadButton.removeClass('disabled').prop('disabled', false);
                    $('.waitOnAjaxUpload').removeClass('wait');

                } else {
                    uploadButton.addClass('disabled').prop('disabled', true);
                    $('.waitOnAjaxUpload').addClass('wait');
                }
            };

            $(document).bind('CurrentEtapeTabChanged', function () {
                // Super important, replace correctement les elements quand la tab d'etape courante à été changée
                uploader.refresh(); // Reposition Flash/Silverlight
            });

            var holdStart = false;

            uploader.bind('FilesAdded', function (up, files) {
                $.each(files, function (index, file) {
                    if ($.fn.fileUploadSettings.forbiddenRegex.test(file.name)) {
                        alert("'" + file.name + "' : Ce type de fichier n'est pas autorisé");
                        up.removeFile(file);
                        holdStart = true;

                    } else {
                        holdStart = false;
                    }
                });
            });

            uploader.bind('QueueChanged', function (up, files) {
                if (holdStart) {
                    return;
                }

                toggleUploadButton(false);

                // Autostart upload
                uploader.start();

                // MR 13/12/2011 : On disable tous les boutons dans la partie Actions
                // CB 15/12/2011 : Attention on ne disable que se qu'on peut renabler apres
                $(".actions input[type=submit]:not([data-validates=False])").attr("disabled", "disabled");

                uploadBar.parents('.clearfix').removeClass('error').parents('td').removeClass('error');

                // MR 16/12/2011 : ajout loading
                $(uploadBar).filter(".uploading").html("<img style='position: relative; top: 10px; left: 10px;' src='/Content/assets/ajax-loader.gif' width='31' height='31'  />");
            });

            uploader.bind('Error', function (up, err) {
                up.refresh(); // Reposition Flash/Silverlight

                if (err.code == plupload.FILE_SIZE_ERROR /* Fichier trop grand*/) {
                    alert("La taille des fichiers est limitée à 10 Mo");
                }

                if (err.code == plupload.FILE_EXTENSION_ERROR /* Extension interdite */) {
                    alert("Ce type de fichier n'est pas autorisé");
                }

                toggleUploadButton(true);
            });

            // Evt un fichier passe
            uploader.bind('UploadFile', function (up, file) {
                //MR 13/12/2011 : ajout notification
                $.sticky("<b>Fichier en cours :</b><br/>" + file.name, { cssClass: 'orange' });
            });

            // Evt global quand toute la queue est terminée
            uploader.bind('UploadComplete', function (up, files) {
                toggleUploadButton(true);

                // MR 13/12/2011 : On enable tous les boutons dans la partie Actions
                // CB 15/12/2011 : Attention on ne renable que se qu'on peut renabler 
                $(".actions input[type=submit]:not([data-validates=False])").removeAttr("disabled");

                // MR 16/12/2011 : Suppr loading
                $(uploadBar).filter(".uploading").html("");

                uploader.refresh(); // Reposition Flash/Silverlight (le layout a pu changer)
            });

            // Evt unitaire pour chaque fichier
            uploader.bind('FileUploaded', function (up, file, info) {

                var data = null;

                try {
                    data = $.parseJSON(info.response);
                } catch (e) {
                    $.sticky("Une erreur est survenue pendant l'envoi du fichier.", { cssClass: 'red' });
                    uploader.refresh();

                    $.ajax({
                        url: '/Home/UploadError',
                        method: 'POST',
                        data: { info: JSON.stringify(info) }
                    });
                } 

                if (data != null) {

                    if (data.Status == true) {

                        //MR 13/12/2011 : ajout notification
                        $.sticky("<b>Fichier uploadé :</b><br/>" + file.name, { cssClass: 'green' });
                        var realData = data.Data;

                        if (realData == null) {
                            //des fois c'est quand meme bon mais on est leger sur la réponse => BIG FILE UPLOAD
                            uploader.refresh();
                            return;
                        }

                        var container = $(realData.html);

                        myFileContainer.trigger('eplans.filesChanging');
                        myFileContainer.data('files-count', container.data('files-count'));
                        myFileContainer.html(container.html());

                        myFileContainer.toggleIfNoFiles();
                        myFileContainer.trigger('eplans.filesChanged');

                        if (realData.title != null && realData.title != '') {
                            myForm.find('.legend').replaceWith(realData.title);
                        }

                        if (realData.validatesFiles != null) {
                            myForm.data('validates-files', realData.validatesFiles);
                        }

                        if (realData.missingFiles != null) {
                            myForm.data('missing-files', realData.missingFiles);
                        }

                        if (realData.warningFiles != null) {
                            myForm.data('warning-files', realData.warningFiles);
                        }

                        if (realData.validates != null) {
                            if (realData.validates == true) { // Pas de question possible
                                myForm.trigger('checkEPlansWarnings'); // On verif les warns quand meme
                                myForm.validationAutorise();

                            } else {
                                // Le cas échéant on laisse la validation inline faire son travail
                                myForm.trigger('checkEPlansValidation');
                            }
                        }

                    } else {
                        if (data.Error != null && data.Error != "") {
                            $.sticky(data.Error, { cssClass: 'red' });
                        }
                    }

                    uploader.refresh(); // Reposition Flash/Silverlight (le layout a pu changer)

                } else {
                    $.sticky("Une erreur est survenue pendant l'envoi du fichier", { cssClass: 'red' });
                }
            });

            uploader.init();
            uploader.refresh();
        });
    };

    // Version simplifiée et plus fléxible
    $.fn.simpleFileUpload = function (settings) {
        settings = settings || {};
        var btnLabel,
            multiFiles = true,
            rejectFileImpl = $.fn.fileUploadSettings.rejectFile;

        if (settings.action == null) {
            return this;
        }

        if ($.isFunction(settings.action)) {
            settings.action = $.proxy(settings.action, this)();
        }

        if (settings.action == '') {
            return this;
        }

        if (settings.btnLabel == null) {
            btnLabel = 'Rattacher';
        } else {
            btnLabel = settings.btnLabel;
        }

        if (settings.multiFiles != null && settings.multiFiles === false) {
            multiFiles = false;
        }

        if (settings.rejectFile != null && $.isFunction(settings.rejectFile)) {
            rejectFileImpl = settings.rejectFile;
        }

        // Wrapper chaque futur fileupload dans un div vide
        return $(this).each(function () {
            var me = $(this);

            if (me.data('uploader') != null || me.parent().data('uploader') != null) {
                return;
            }

            var myGuid = plupload.guid();
            var idBtn = "btnUpload-" + myGuid,
                idContainer = 'objectContainer-' + myGuid,
                idDropZone = 'dropZone-' + myGuid;

            var objectContainer = me.parent();
            objectContainer.attr('id', idContainer);

            if (settings.noButton !== true) {
                var uploadBar = $('<span class="btn btn-default btnAjaxUpload">' +
                    btnLabel +
                    '</span><span class="uploading"></span>');

                var uploadButton = uploadBar.first();
                uploadButton.attr('id', idBtn);

                var dropZone;

                if (settings.noDropZone !== true) {
                    if (settings.dropToMe === true) {
                        dropZone = me.closest('td');
                        dropZone.attr('id', idDropZone).addClass('dropzone');
                    } else {
                        dropZone = me.parents('td');
                        dropZone.attr('id', idDropZone).addClass('dropzone');
                    }
                }

                me.after(uploadBar);
                me.remove();
            } else {
                me.attr('id', idBtn);
                var uploadButton = me;
            }

            var multipartData = {};

            if ($.isFunction(settings.multipartData)) {
                multipartData = $.proxy(settings.multipartData, me)();
            } else if ($.isPlainObject(settings.multipartData)) {
                multipartData = settings.multipartData;
            }

            var cfg = $.extend($.fn.fileUploadSettings.baseConfig, {
                url: settings.action,
                container: idContainer,
                multipart_params: multipartData,
                browse_button: idBtn,
                drop_element: idDropZone,
                multi_selection: multiFiles
            });

            var uploader = new plupload.Uploader(cfg);

            objectContainer.data('uploader', uploader);

            $(document).bind('CurrentEtapeTabChanged', function () {
                // Super important, replace correctement les elements quand la tab d'etape courante à été changée
                uploader.refresh(); // Reposition Flash/Silverlight
            });

            var refusedFiles = [];

            var holdStart = false;

            var toggleUploadButton = function (enable) {
                if (enable) {
                    uploadButton.removeClass('disabled').prop('disabled', false);
                    $('.waitOnAjaxUpload').removeClass('wait');

                } else {
                    uploadButton.addClass('disabled').prop('disabled', true);
                    $('.waitOnAjaxUpload').addClass('wait');
                }
            };

            uploader.bind('FilesAdded', function (up, files) {
                $.each(files, function (index, file) {
                    // TM 28/06/2017: soit on autorise les extension matchant allowedRegex quand il est non null (mis en place pour commandes),
                    // soit on fait le test classique qui au contraire exclut les extensions matchant forbiddenRegex
                    if (rejectFileImpl(file.name)) {
                        alert("'" + file.name + "' : Ce type de fichier n'est pas autorisé");
                        refusedFiles.push(file);

                        holdStart = true;
                    } else {
                        holdStart = false;
                    }
                });

                if (typeof settings.updateDataBeforeUpload == "boolean" && settings.updateDataBeforeUpload) {
                    if ($.isFunction(settings.multipartData)) {
                        objectContainer.data('uploader').settings.multipart_params = $.proxy(settings.multipartData, me)();
                    }
                }

                if (settings.onFilesAdded != null && $.isFunction(settings.onFilesAdded)) {
                    $.proxy(settings.onFilesAdded, me)(up, files);
                }
            });

            uploader.bind('QueueChanged', function (up) {
                $.each(up.files || [], function (ix, file) {
                    if (refusedFiles.indexOf(file) > -1) {
                        up.removeFile(file);
                    }
                });

                if (holdStart) {
                    return;
                }

                toggleUploadButton(false);

                // Autostart upload
                uploader.start();

                // MR 16/12/2011 : ajout loading
                $(uploadBar).filter(".uploading").html("<img style='position: relative; top: 10px; left: 10px;' src='/Content/assets/ajax-loader.gif' width='31' height='31'  />");

                if (settings.onQueueChanged != null && $.isFunction(settings.onQueueChanged)) {
                    $.proxy(settings.onQueueChanged, me)(up, files);
                }
            });

            uploader.bind('Error', function (up, err) {
                up.refresh(); // Reposition Flash/Silverlight

                if (err.code == plupload.FILE_SIZE_ERROR /* Fichier trop grand*/) {
                    alert("La taille des fichiers est limitée à 10 Mo");
                }

                if (err.code == plupload.FILE_EXTENSION_ERROR /* Extension interdite */) {
                    alert("Ce type de fichier n'est pas autorisé");
                }

                toggleUploadButton(true);

                if (settings.onError != null && $.isFunction(settings.onError)) {
                    $.proxy(settings.onError, me)(up, err);
                }
            });

            // Un fichier est passé
            uploader.bind('UploadFile', function (up, file, info) {
                //MR 13/12/2011 : ajout notification
                $.sticky("<b>Fichier en cours :</b><br/>" + file.name, { cssClass: 'orange' });

                if (settings.onUploadFile != null && $.isFunction(settings.onUploadFile)) {
                    $.proxy(settings.onUploadFile, me)(up, file, info);
                }
            });

            // Toute la queue est passée
            uploader.bind('UploadComplete', function (up, files, info) {
                toggleUploadButton(true);

                // MR 16/12/2011 : Suppr loading
                $(uploadBar).filter(".uploading").html("");

                uploader.refresh(); // Reposition Flash/Silverlight (le layout a pu changer)

                if (settings.onUploadFinished != null && $.isFunction(settings.onUploadFinished)) {
                    $.proxy(settings.onUploadFinished, me)(up, files, info);
                }
            });

            // Un fichier unitaire est passé
            uploader.bind('FileUploaded', function(up, file, info) {
                if (settings.onFileUploaded != null && $.isFunction(settings.onFileUploaded)) {
                    $.proxy(settings.onFileUploaded, me)(up, file, info);
                }
            });

            uploader.init();
            uploader.refresh();
        });
    };
})(jQuery);
