﻿import { TypeControleChantierReponse, TypeStatutNonConformiteEnum, TypeModeControle, TypeStatutControleEnum, TypeActionEnum, TypeFichierEnum } from "./common";
import { ControleAction } from "./controle-action";
import { ControleFichier } from "./controle-fichier";
// ReSharper disable  InconsistentNaming

export class ControleReponse {
    constructor(modeControle: string, statutControle: Number, idControle: Number, currentUser: string) {
        this.ModeControle(modeControle);
        this.StatutControle(statutControle);
        this.CurrentUtilisateur = currentUser;
        this.idControle = idControle;

        this.Actions.extend({ rateLimit: 50 });

        this.Value.subscribe(() => {
            //
            var val = parseInt(this.Value());

            if (val === TypeControleChantierReponse.Conforme) {
                this.StatutReponse(TypeStatutNonConformiteEnum.Conforme);
            }

            if (val === TypeControleChantierReponse.NonObservé) {
                this.StatutReponse(TypeStatutNonConformiteEnum.NonObservé);
            }

            //EPL-11882 :Si suite à un enregistrement en brouillon on switch de non-observé à non-conforme il faut que l'on puisse éditer une justification,
            // actuellement ça ne fonctionne pas car aucun statut n'est redéfini
            if(val === TypeControleChantierReponse.NonConforme &&
                this.StatutControle() === TypeStatutControleEnum.Brouillon){
                this.StatutReponse(TypeStatutNonConformiteEnum.Brouillon)
            }
        });

        this.NewReponse.subscribe(() => {
            var val = parseInt(this.NewReponse());
            //dans le cas d'une demande contestation il faut faire la distinction conforme / non-observé
            if (this.StatutReponse() === TypeStatutNonConformiteEnum.ContesteConforme) {
                if (val === TypeControleChantierReponse.Conforme) {
                    this.StatutReponse(TypeStatutNonConformiteEnum.ContesteConforme);
                }

                if (val === TypeControleChantierReponse.NonObservé) {
                    this.StatutReponse(TypeStatutNonConformiteEnum.ContesteNonObserve);
                }
            }

        });
    }

    Id = ko.observable();
    FkIdQuestion = ko.observable(); //id de la question
    Value = ko.observable<any>();
    OldValue = ko.observable<any>();
    Bloquante = ko.observable(false);

    Justification = ko.observable<string>();
    NouvelleJustification = ko.observable<string>();
    CommentairePrestaEvalFournisseur = ko.observable<string>();

    Commentaire = ko.observable<string>();

    Author = ko.observable<string>();
    AuthorId = ko.observable<string>();
    Valideur = ko.observable<string>();
    ValideurId = ko.observable<string>();

    StatutReponse = ko.observable<number>();
    Niveau: number;

    DateValidation = ko.observable<Date>();
    DateNonConforme = ko.observable<Date>();

    NewReponse = ko.observable<any>();

    CurrentUtilisateur: string;
    idControle: Number;

    ListeFichiers = ko.observableArray<ControleFichier>();
    Actions = ko.observableArray<ControleAction>();

    IsEvaluationFournisseur = ko.observable<boolean>(false);

    SetDroit = () => {
        //le droit d'attacher des fichiers
        var mc = this.ModeControle();

        this.IsFileAttachActive((mc === TypeModeControle.Controleur.toString() || mc === TypeModeControle.EvalFounisseurEvaluateur.toString()) &&
            this.StatutReponse() !== TypeStatutNonConformiteEnum.Validee &&
            this.StatutReponse() !== TypeStatutNonConformiteEnum.ValideeAutomatiquement
                /*&& this.DateNonConforme()===null*/);
    }

    JustificationLibelle = ko.computed(() => {
        if (this.IsConforme()) {
            return "Observations";
        }
        return "Justification";
    });

    NeedToShowTogglableObs = ko.computed(() => {
        if (this.IsConforme()) {
            return (this.StatutControle() === TypeStatutControleEnum.Brouillon ||
                (this.StatutControle() > TypeStatutControleEnum.Brouillon &&
                    (this.Justification() !== '' ||
                        this.ListeFichiers().length > 0)));
        }
        return false;
    });
    WantToAjouteObservations = ko.observable<Boolean>(false);
    ToggleAjouteObservations = () => {
        this.WantToAjouteObservations(!this.WantToAjouteObservations());
    }

    ToggleAjouteObservationsLibelle = ko.computed(() => {
        if (this.WantToAjouteObservations()) {
            return "Réduire";
        }
        if (this.StatutControle() > TypeStatutControleEnum.Brouillon &&
            (this.Justification() !== '' || this.ListeFichiers().length > 0)) {
            return "Voir les observations";
        }
        return "Ajouter des observations";
    });
    /**
     * autorise au controleur de répondre au radio button      
     */
    CanDoControle = ko.computed(() => {
        let sc = this.StatutControle(),
            vl = parseInt(this.Value()),
            mc = this.ModeControle();
       
        if (mc === TypeModeControle.Controleur.toString() || mc === TypeModeControle.EvalFounisseurEvaluateur.toString()) {
           
            //si le controle est en mode reprise/complement => on ne peut pas modifier les anciennes réponses
            if (sc === TypeStatutControleEnum.EnAttenteReprise) {               
                return vl === TypeControleChantierReponse.NonConforme;
            }
          
            return sc < TypeStatutControleEnum.EnAttenteReprise ||
                sc === TypeStatutControleEnum.ControleARealiser;
        }

        return false;
    });


    /**
     * Etat de la réponse
     */

    Repondu = ko.pureComputed(() => {
        var val = parseInt(this.Value());

        return val !== TypeControleChantierReponse.Null;
    });

    Valide = ko.pureComputed(() => {
        var val = parseInt(this.Value());

        return val >= TypeControleChantierReponse.NonConforme;
    });

    IsConforme = ko.pureComputed(() => {
        var val = parseInt(this.Value());

        return val === TypeControleChantierReponse.Conforme;
    });

    IsNonConforme = ko.pureComputed(() => {
        var val = parseInt(this.Value());

        return val === TypeControleChantierReponse.NonConforme;
    });

    IsNonConformeOrHasActions = ko.pureComputed(() => {
        let inc = this.IsNonConforme(),
            act = this.Actions();

        return inc || act.length > 0;
    });

    HasNoActions = ko.pureComputed(() => {
        let act = this.Actions();

        return act.length === 0;
    });

    IsNonConformeCompletude = ko.pureComputed(() => {
        var val = parseInt(this.Value()),
            bl = !this.Bloquante();
        return val === TypeControleChantierReponse.NonConforme && bl;
    });

    IsNonConformeBloquanteCompletude = ko.pureComputed(() => {
        var val = parseInt(this.Value()),
            bl = this.Bloquante();

        return val === TypeControleChantierReponse.NonConforme && bl;
    });

    /**
     * gestion des actions associé demande de complement 
     */
    IsNonConformiteContestee = ko.computed(() => {
        return (this.StatutReponse() === TypeStatutNonConformiteEnum.ContesteConforme ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.ContesteNonObserve);
    });


    IsNonConformiteRefusee = ko.computed(() => {
        return (this.StatutReponse() === TypeStatutNonConformiteEnum.ContestationRefusee ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.LeveeRefusee);
    });

    IsNonConformiteValide = ko.computed(() => {
        return (this.StatutReponse() === TypeStatutNonConformiteEnum.Validee ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.ValideeAutomatiquement);
    });

    ShowAskDemandeDeLevee = ko.computed(() => {
        var vl = parseInt(this.Value());

        // Non conformité toute neuve
        if ((this.StatutReponse() === TypeStatutNonConformiteEnum.Validee ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.ValideeAutomatiquement)) {
            return true;
        }

        // Non conformité avec une demande de contestataion ou demande de levée non accepté
        switch (this.StatutReponse()) {
            case TypeStatutNonConformiteEnum.LeveeRefusee:
            case TypeStatutNonConformiteEnum.ContestationRefusee:
                return true;

            case TypeStatutNonConformiteEnum.Levee:
                return vl === 0;
        }
        return false;
    });

    IsNomCorformiteEnAttenteValidation = ko.computed(() => {
        return (this.StatutReponse() === TypeStatutNonConformiteEnum.Brouillon ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteValidation ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteReprise);
    });

    IsNomCorformiteEnAttenteValidationFirst = ko.computed(() => {
        let act = this.Actions();

        return act.length === 0 &&
            (this.StatutReponse() === TypeStatutNonConformiteEnum.Brouillon
                || this.StatutReponse() === TypeStatutNonConformiteEnum.Conforme
                || this.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteValidation);
    });

    IsNomCorformiteEnAttenteReprise = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteReprise;
    });

    ShowButtonValidEtDemandeComplement = ko.pureComputed(() => {
        let vld = this.ValidationDone(),
            act = this.Actions();

        var f = (this.StatutReponse() === TypeStatutNonConformiteEnum.Conforme ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteValidation ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.EnAttenteReprise);

        if (!f) {
            return false;
        }
        if (vld) {
            return false;
        }

        var ret = true;

        $.each(act,
            (ix, a: ControleAction) => {
                if (a.TypeAction() === TypeActionEnum.DemandeDeRepriseComplement && !a.IsSend()) {
                    ret = false;
                    return false;
                }
            });
        return ret;
    });

    HasReponseComplement = ko.computed(() => {
        let act = this.Actions();

        // Le statut de la réponse doit être au moins à  "en attente de reprise"
        if (this.StatutReponse() !== TypeStatutNonConformiteEnum.EnAttenteReprise) {
            return false;
        }

        var ret = false;
        $.each(act,
            (ix, a: ControleAction) => {
                if (a.TypeAction() === TypeActionEnum.ReponseDeRepriseComplement) {
                    ret = true;
                    return false;
                }
            });

        return ret;
    });

    /**
     * y a t il eu des retours prestataire de type contestation et demande de levée
     */
    HasRetourPrestataire = ko.pureComputed(() => {
        var ret = false;
        $.each(this.Actions(),
            (ix, a: ControleAction) => {
                if (a.TypeAction() >= TypeActionEnum.DemandeDeLevee) {
                    ret = true;
                    return false;
                }
            });

        return ret;
    });

    HasNotRetourPrestataire = ko.computed(() => {
        return !this.HasRetourPrestataire();
    });

    JustificationFinal = ko.computed(() => {
        let nvj = this.NouvelleJustification(),
            j = this.Justification();

        if (nvj !== null && nvj !== "") {
            return nvj;
        }

        return j;

    });

    CancelDemandeComplement = (demande) => {
        this.Actions.remove(demande);
        this.StatutReponse(TypeStatutNonConformiteEnum.EnAttenteValidation);
    };

    AskDemandeComplement = () => {
        var demande = new ControleAction(this,
            this.ModeControle(),
            this.CurrentUtilisateur,
            TypeActionEnum.DemandeDeRepriseComplement);

        this.Actions.push(demande);

        //on mets à jours le statut de la réponse
        this.StatutReponse(TypeStatutNonConformiteEnum.EnAttenteReprise);
    };

    SendDemandeComplement = () => {
        var demande = new ControleAction(this,
            this.ModeControle(),
            this.CurrentUtilisateur,
            TypeActionEnum.ReponseDeRepriseComplement);

        this.Actions.push(demande);

        this.StatutReponse(TypeStatutNonConformiteEnum.EnAttenteReprise);
    };

    ValideNonConformite = () => {
        this.StatutReponse(TypeStatutNonConformiteEnum.Validee);
        this.Valideur(this.CurrentUtilisateur);
        this.DateValidation(new Date());
        this.ValidationDone(true);
    };

    ValidationDone = ko.observable<Boolean>(false);

    SendReponseComplement = () => {
        this.StatutReponse(TypeStatutNonConformiteEnum.EnAttenteValidation);
    };


    /**
     * Demande de levée et Contestation
     */


    //juste pour bine rétablir entre "validé" et "validé automatiquement"
    private _oldStatut: any;

    PrepareDemandeDeLevee = ko.observable<boolean>(false);
    PrepareContestation = ko.observable<boolean>(false);

    /**
    * COMMUN
    */
    Prepare = ko.computed(() => {
        let pddl = this.PrepareDemandeDeLevee(),
            pc = this.PrepareContestation();

        return pddl || pc;
    });


    /**
     * Contestation
     */
    AskContestation = () => {
        var demande = new ControleAction(this,
            this.ModeControle(),
            this.CurrentUtilisateur,
            TypeActionEnum.DemandeContestation);

        this.Actions.push(demande);

        //on mets à jours le statut de la réponse
        this._oldStatut = this.StatutReponse();
        this.StatutReponse(TypeStatutNonConformiteEnum.DemandeContestation);
        this.PrepareContestation(true);
    };
    CancelContestation = (demande) => {
        this.Actions.remove(demande);
        this.StatutReponse(this._oldStatut);
        this.PrepareContestation(false);
    };
    RefuseContestation = () => {
        this._oldStatut = this.StatutReponse();
        this.StatutReponse(TypeStatutNonConformiteEnum.ContestationRefusee); //pour la traçabilité
        //this.Value(this.OldValue());//entre "validé" et "validé automatiquement"
    };
    AccepteContestation = () => {
        this._oldStatut = this.StatutReponse();
        this.StatutReponse(TypeStatutNonConformiteEnum.ContesteConforme);
    };
    CancelReponseAContestation = () => {
        this.StatutReponse(this._oldStatut);
    };

    /**
     * Demande de levée
     */
    AskDemandeDeLevee = () => {
        var demande = new ControleAction(this,
            this.ModeControle(),
            this.CurrentUtilisateur,
            TypeActionEnum.DemandeDeLevee);

        this.Actions.push(demande);

        //on mets à jours le statut de la réponse
        this._oldStatut = this.StatutReponse();
        this.StatutReponse(TypeStatutNonConformiteEnum.DemandeDeLevee);
        this.PrepareDemandeDeLevee(true);
    };

    CancelDemandeDeLevee = (demande) => {
        this.Actions.remove(demande);
        this.StatutReponse(this._oldStatut);
        this.PrepareDemandeDeLevee(false);
    };

    RefuseDemandeDeLevee = () => {
        this._oldStatut = this.StatutReponse();
        this.StatutReponse(TypeStatutNonConformiteEnum.LeveeRefusee);
        //this.Value(this.OldValue());
    };

    AccepteDemandeDeLevee = () => {
        this._oldStatut = this.StatutReponse();
        this.StatutReponse(TypeStatutNonConformiteEnum.Levee);
    };

    CancelReponseALevee = () => {
        this.StatutReponse(this._oldStatut);
    };

    /*
    * Gestion des statuts
    */

    IsValideAuto = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.ValideeAutomatiquement;
    });

    IsStatutValidee = ko.computed(() => {
        if ((this.StatutReponse() === TypeStatutNonConformiteEnum.Validee ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.ValideeAutomatiquement)
        ) {
            return true;
        }
        return false;
    });


    /*
    * STATUTS
    */

    //dès qu'il y a une demande prestataire on lui affiche ce statuts
    IsStatutAttenteReponseERDF = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.DemandeDeLevee ||
            this.StatutReponse() === TypeStatutNonConformiteEnum.DemandeContestation;
    });

    IsStatutContestationRefusee = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.ContestationRefusee;
    });

    IsStatutContesteeConforme = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.ContesteConforme;
    });

    IsStatutContesteeNonObserve = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.ContesteNonObserve;
    });

    IsStatutLeveeRefusee = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.LeveeRefusee;
    });

    IsStatutLeve = ko.computed(() => {
        return this.StatutReponse() === TypeStatutNonConformiteEnum.Levee;
    });

    /*
    * icone en début de ligne
    */
    iconReponse = ko.pureComputed(() => {
        var val = parseInt(this.Value());

        switch (val) {
            case TypeControleChantierReponse.Conforme:
                let act = this.Actions(),
                    icon = '';

                $.each(act,
                    (ix, a: ControleAction) => {
                        if (a.IsDemandeDeLevee()) {
                            let dt = a.DateAction();
                            if (dt !== undefined && moment(dt).year() > 2000) {
                                icon = "fa fa-1x fa-remove text-danger";
                            }
                            return false;
                        }
                    });

                return icon === '' ? "fa fa-1x fa-check text-success" : icon;

            case TypeControleChantierReponse.NonObservé:
                return "fa fa-1x fa-eye-slash text-info";

            case TypeControleChantierReponse.NonConforme:
                return this.Bloquante()
                    ? "fa fa-1x fa-remove text-danger"
                    : "fa fa-1x fa-remove text-warning";

            default:
                return "";
        }
    });

    CssJustification = ko.computed(() => {
        let incbc = this.IsNonConformeBloquanteCompletude(),
            isStatusLevee = this.IsStatutLeve(),
            isStatusContesteeConforme = this.IsStatutContesteeConforme(),
            isStatusContesteeNonObserve = this.IsStatutContesteeNonObserve(),
            isConforme = this.IsConforme(),
            isBloquant = this.Bloquante();

        if (isStatusContesteeConforme || isStatusLevee || isConforme) {
            return "alert alert-success";
        }

        if (isStatusContesteeNonObserve) {
            return "alert alert-default";
        }

        if (isBloquant) {
            return "alert alert-danger";
        }

        return incbc ? "alert alert-danger" : "alert alert-warning";
    });

    /*
    * fichiers
    */

    TypeFichier = ko.observable<Number>(TypeFichierEnum.ControleChantierJustification);

    AddFichier = (f: ControleFichier) => {
        this.ListeFichiers.push(f);
    };

    RemoveFichier = (f: ControleFichier) => {
        var data = {};

        data["__RequestVerificationToken"] = $.fn.findValidationToken();
        data["id"] = f.Id();

        $.ajax({
            url: '/GED/DeleteFile',
            type: 'POST',
            data: data
        }).always((e) => {
            this.ListeFichiers.remove(f);
        });
    };

    /**
     * Mode de fonctionnement du formulaire
     */
    IsFileAttachActive = ko.observable<Boolean>(false);

    ModeControle = ko.observable<string>(TypeModeControle.Controleur.toString());
    StatutControle = ko.observable<Number>(0);
}
