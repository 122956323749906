﻿// ReSharper disable InconsistentNaming
/**
     * Champs à ne pas faire passer au post (passé à ko.mapping)
     */
export const IgnoredFields = [
    "",
    "_beforeMapping",
    "_computeCompletudeConformiteControle",
    "_computeCompletudeControle",
    "_computeConformiteControle",
    "_deferDateAction",
    "_fixedCompletudeConformiteControle",
    "_fixedCompletudeControle",
    "_fixedConformiteControle",
    "AccepteContestation",
    "AccepteDemandeDeLevee",
    "AddFichier",
    "AideCourte",
    "AideLongue",
    "Answered",
    "AskContestation",
    "AskDemandeComplement",
    "AskDemandeDeLevee",
    "Author",
    "CancelContestation",
    "CancelDemandeComplement",
    "CancelDemandeDeLevee",
    "CancelPrepareAccepteContestation",
    "CancelPrepareAccepteContestation",
    "CancelPrepareAccepteDemandeDeLevee",
    "CancelPrepareRefusContestation",
    "CancelPrepareRefusDemandeDeLevee",
    "CancelReponseAContestation",
    "CancelReponseALevee",
    "constructor",
    "Contestations",
    "CssJustification",
    "CssLine",
    "DelaiReponsePrestataire",
    "DemandeComplement",
    "DemandeLevee",
    "ErrorMessage",
    "Ext",
    "GammeControle",
    "hasAide",
    "hasAideLongue",
    "HasDemandeComplement",
    "HasError",
    "HasNotDemandeComplement",
    "HasNotRetourPrestataire",
    "HasReponseComplement",
    "HasRetourPrestataire",
    "helpVisible",
    "iconReponse",
    "IsConforme",
    "IsContestation",
    "IsDemandeComplement",
    "IsDemandeDeLevee",
    "IsEmptySlot",
    "IsFileAttachActive",
    "IsFileAttachActiveInterne",
    "IsFileAttachActivee",
    "IsNomCorformiteEnAttenteValidation",
    "IsNonConforme",
    "IsNonConforme",
    "IsNonConformeBloquante",
    "IsNonConformeBloquante",
    "IsNonConformiteValide",
    "isOrderByOrdre",
    "isOrderByTheme",
    "IsReponseComplement",
    "IsReponseContestation",
    "IsReponseDemandeDeLevee",
    "IsStatutAttenteReponseERDF",
    "IsStatutContestationRefusee",
    "IsStatutContesteeConforme",
    "IsStatutContesteeNonObserve",
    "IsStatutLeve",
    "IsStatutLeveeRefusee",
    "IsStatutValidee",
    "IsValid",
    "LastAction",
    "LastActiveAction",
    "LastActiveActions",
    "LastFinishedAction",
    "Libelle",
    "ModeControle",
    "NomFichier",
    "NumeroAffaire",
    "orderBy",
    "orderByOrdre",
    "orderByTheme",
    "OrderedActions",
    "Ordre",
    "ParentReponse",
    "Prepare",
    "PrepareAcceptation",
    "PrepareAccepteContestation",
    "PrepareAccepteDemandeDeLevee",
    "PrepareContestation",
    "PrepareDemandeDeLevee",
    "PrepareRefus",
    "PrepareRefusContestation",
    "PrepareRefusDemandeDeLevee",
    "RefuseContestation",
    "RefuseDemandeDeLevee",
    "RemoveFichier",
    "ReponseAction",
    "SendDemandeComplement",
    "SendReponseComplement",
    "SetDroit",
    "SeuilConformite",
    "showAideLongue",
    "showAideLongue",
    "ShowAskDemandeDeLevee",
    "ShowButtonValidEtDemandeComplement",
    "Theme",
    "toggleHelp",
    "Url",
    "Valid",
    "ValidAndSend",
    "ValidationDone",
    "ValideNonConformite",
];

export interface IMappingCallbacks {
    _beforeMapping();
}

export enum TypeControleChantierReponse {
    Null = -2,
    NonObservé = -1,
    NonConforme = 0,
    Conforme = 1
}

export enum TypeModeControle {
    NoAcces = -1,
    Controleur = 0,
    Validateur = 1,
    Prestataire = 2,
    LectureSeul = 3,
    EvalFounisseurEvaluateur = 4,
    EvalFounisseurValideur = 5,
}

export enum TypeStatutControleEnum {
    Brouillon = 0,
    EnAttenteValidation = 2,
    EnAttenteReprise = 4,
    EnAttenteReponsePrestataire = 6,
    EnAttenteReponseEnedis = 8,
    EnAttenteReponseEnedisEtPrestataire = EnAttenteReponseEnedis | EnAttenteReponsePrestataire,
    Termine = 10,
    ControleARealiser = 50
}

export enum TypeStatutNonConformiteEnum {
    Brouillon = -2,

    Conforme = 0,

    NonObservé = -1,

    Validee = 1,
    ValideeAutomatiquement = 2,

    EnAttenteValidation = 10,
    EnAttenteReprise = 11,

    DemandeContestation = 20,
    ContesteConforme = 21,
    ContesteNonObserve = 22,
    ContestationRefusee = 23,

    DemandeDeLevee = 30,
    Levee = 31,
    LeveeRefusee = 32,
}

export enum TypeActionEnum {
    DemandeDeRepriseComplement = 0,
    ReponseDeRepriseComplement = 1,
    DemandeDeLevee = 10,
    ReponseDemandeDeLevee = 11,
    DemandeContestation = 20,
    ReponseContestation = 21
}

export enum TypeFichierEnum {
    ControleChantierJustification = 10,
    ControleChantierDemandeDeRepriseComplement = 11,
    ControleChantierReponseRepriseComplement = 12,
    ControleChantierDemandeDeLevee = 13,
    ControleChantierReponseDemandeDeLevee = 14,
    ControleChantierContestation = 15,
    ControleChantierReponseContestation = 16,
    IcpAutresContraintes = 102,
    IcpConsistanceTravaux = 103,

    GdaPreparationAccesDoc = 40,
    GdaPreparationAccesDocInterne = 41,

}

// ReSharper restore InconsistentNaming
