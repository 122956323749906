﻿(function ($) {

    function validateCommentaire(field) {
        var allowCaracs = /[^\r\n\x20-\x7DçÇäàâÄÀÂéêëèÉÊËÈïîÏÎôöÔÖ²]/i;
        var newValue = field.val() || "";
        field.val(newValue.replace(/[^\r\n\x20-\xFF]+/g, ''));
        newValue = field.val() || "";
        var interdit = newValue.match(allowCaracs);

        var clearfix = field.parents('.clearfix');
        clearfix.removeClass('error');
        field.siblings('span.error-commentaire').remove();
        if (interdit) {
            clearfix.addClass('error');
            field.after('<span class="help-block error-commentaire">Le caractère ' + interdit[0] + ' est interdit.</span>');
            return false;
        }
        return true;
    }


    $.fn.extend({
        validForm: function ($form, mustSubmit) {
            var toValidate = $form.find("input.required, textarea.required");

            for (var i = 0; i < toValidate.length; i++) {
                var elem = toValidate[i],
                    $e = $(elem);

                var clearfix = $e.parents('.clearfix');
                if ($e.val() === null || $e.val() === '') {
                    clearfix.addClass('error');
                    return false;
                } else {
                    clearfix.removeClass('error');
                }
            }

            var commentaires = $form.find("input.commentaire, textarea.commentaire");
            for (var iComm = 0; iComm < commentaires.length; iComm++) {
                if (!validateCommentaire($(commentaires[iComm])))
                    return false;
            }

            if (mustSubmit) {
                $form.submit();
            }

            return true;
        }
    });

    $.fn.prepareSave = function ($f, $myModalePa, $btSave) {
        if ($btSave.hasClass('disabled')) {
            return;
        }

        $btSave.addClass('disabled');

        //submit qui a submiter
        var action = $btSave.data("action");

        if (action === "Solder") {
            $f.find('.required').removeClass('required');
        }

        $myModalePa.find('input[type="hidden"][name="Action"]').val(action);
    };

    $.fn.bindClassicClick = function($f, $myModalePa, $btSave) {
        //  TM 29/05/2018 : on unbind pour nettoyer les éventuels bindings déjà effectués
        // Introduits suite à la nécessité d'effectuer une action un peu différente sur PPOSR (cf. pposr-travaux-planification.ts)
        $btSave.unbind("click");

        $btSave.click(function(e) {
            e.preventDefault();

            //MR 16/04/2012 : on désactive le bouton pour éviter les doublons
            var $mBt = $(this);

            $.fn.prepareSave($f, $myModalePa, $mBt);

            var validForm = $.fn.validForm($f, true);

            if (!validForm) {
                $mBt.removeClass('disabled');
            }
        });
    };

    $.fn.bindSpecialBranchementClick = function($formPa, $myModalePa, validDatas, callback, source) {
        var $btnSavePa = $(this);
        $btnSavePa.unbind("click");

        $btnSavePa.click(function(e) {
            e.preventDefault();

            //MR 16/04/2012 : on désactive le bouton pour éviter les doublons
            var $btn = $(this);

            $.fn.prepareSave($formPa, $myModalePa, $btn);

            var validForm = $.fn.validForm($formPa, false);

            // Le formulaire de PA n'est pas valide
            if (!validForm) {
                $btn.removeClass('disabled');
            }

            // Le form de PA est valide, si les données le sont aussi on dépose le PA pour pouvoir sauver ensuite
            else if (validDatas) {
                // Pas de refresh de la vue au dépôt du PA car on veut sauver après
                $formPa.find('[name="MustRefresh"]').val("False");
                var files = $formPa.find('[name="PostedPieces[0]"]')[0].files[0];

                var serializedArray = $formPa.serializeArray();
                var formData = new FormData();

                for (var i = 0; i < serializedArray.length; i++) {
                    formData.append(serializedArray[i].name, serializedArray[i].value);
                }

                // On append les files à la main car ça ne passe pas dans le serializeArray
                formData.append('PostedPieces', files);

                $myModalePa.find('.modal-footer').hide();
                $myModalePa.find('.point-arret-modal-loading').show();

                $.ajax({
                    url: '/Affaire/PointArretDepose',
                    type: 'POST',
                    contentType: false,
                    processData: false,
                    data: formData
                }).done(function(result) {
                    if (result.Status) {
                        $.fn.sticky(result.Message, { cssClass: 'green' });
                        callback(source);

                    } else {
                        $.fn.sticky(result.Message, { cssClass: 'orange' });
                    }
                });
            }
        });
    };

    $.fn.echangeInformationFiles = function (options) {
        var s = $.extend({
            BaseName: 'PostedPieces',
            Width: '500px',
            ContainerSelector: 'div.echange-info-files'
        }, options || {});

        return $(this).each(function () {
            var me = $(this);

            if (me.find(s.ContainerSelector).length === 0) {
                return;
            }

            var appendDeleteBehavior = function($me) {
                var derp = $me.parents('.input').find('input[type=file]');

                derp.val('');

                $me.hide();

                return false;
            };

            var addFileField = function () {
                var inputSelector = s.ContainerSelector + ' input[type=file][name^="' + s.BaseName + '["]',
                    inputs = me.find(inputSelector);

                // Pas la peine de rajouter des inputs si il y en a sans fichier de mis dispo
                var emptyOne = false;

                $.each(inputs, function (idx, elem) {
                    var $elem = $(elem),
                        $clear = $elem.parents('.input').find('.clear-file');

                    if ($elem.val() === null || $elem.val() === '') {
                        $clear.hide();
                        emptyOne = true;
                    } else {
                        $clear.show();
                    }
                });

                // Il y a de place, tu te servira de celui là
                if (emptyOne) {
                    return false;
                }

                var nb = inputs.length;

                var $container = $('<div></div>');
                $container.addClass('input file-input');

                var $input = $('<input/>');
                $input.addClass('echange-info-file')
                    .css('display', 'inline-block').css('width', s.Width)
                    .attr('size', '65').attr('type', 'file').attr('name', s.BaseName + '[' + nb + ']');

                var $deleteLink = $('<a></a>');
                $deleteLink.addClass('clear-file').attr('href', "#");
                $deleteLink.css('display', 'none');

                var deleteImg = '<img src="/Content/assets/delete.png" alt="X" width="16" height="16" />';

                $deleteLink.append(deleteImg);

                // On bind le comportement au click sur cet élément nouvellement ajouté
                $deleteLink.click(function() {
                    var $me = $(this);

                    appendDeleteBehavior($me);
                });

                $container.append($input);
                $container.append($deleteLink);
                me.find(s.ContainerSelector).append($container);

                return true;
            };

            // Bind initial sur la ligne présente à l'ouverture de la page pour le bouton delete'
            me.find(s.ContainerSelector + ' .clear-file').on('click', function () {
                var $me = $(this);

                appendDeleteBehavior($me);
            });

            // Permet d'avoir le comportement attendu à l'ajout d'un fichier même sur les lignes ajoutées après chargement de la vue
            $(document).on('change',
                me.find(s.ContainerSelector + ' input[type=file]'),
                function() {
                    addFileField();
                });
        });
    };

    $.fn.echangeInformation = function () {
        return this.each(function () {
            var $me = $(this),
                $f = $me.find('form:not(.form-echange-ignore)'),
                //clic sur sauvegarder
                $btSave = $me.find('.echange-info-save');

            //pour echange info existant
            var echangeId = $me.data("echange-info-id");

            //pour nouveau echange Info
            var actionId = $me.data("action-id"),
                etapeId = $me.data("etape-id"),
                affEtapeId = $me.data("affaireetape-id"),
                entreprise = $me.data("entreprise"),
                tranche = $me.data("tranche"),
                $selected = $me.find("select[name='TypeObjetNorme']");

            if (!$selected) {
                $selected.removeAttr('disabled');
            }

            //click sur déclencheur modal
            $('a[data-echange-info-id="' + echangeId + '"], input[type=submit][data-echange-info-id="' + echangeId + '"]').click(function (e) {
                e.preventDefault();
                $.fn.bindClassicClick($f, $me, $btSave);
                $me.modal('show');
            });

            // Dans le cas PPOSR on aura un Id d'AffaireEtape, on l'ajoute pour afficher la bonne modale
            var selectorPa1 = 'a[data-action-id="' + actionId + '"][data-etape-id="' + etapeId + '"][data-entreprise="' + entreprise + '"][data-tranche="' + tranche + '"]',
                selectorPa2 = "input[type=submit][data-action-id='" + actionId + "'][data-etape-id='" + etapeId + "'][data-entreprise='" + entreprise + "'][data-tranche='" + tranche + "']";

            // TM 23/04/2018 : dans le cas du PA PPOSR, on aura besoin que de data-id-affaire-etape car on a une modale par prestation et donc aussi par étape
            if (affEtapeId && affEtapeId > 0) {
                selectorPa2 = selectorPa2 + "[data-id-affaire-etape='" + affEtapeId + "']";
            }

            var selectorPa = selectorPa1 + "," + selectorPa2;

            $(selectorPa)
                .click(function (e) {
                    e.preventDefault();

                    var $btn = $(this),
                        activeTabVal = $btn.data('activetab'),
                        wfGroupVal = $btn.data('id-workflowgroupe');

                    // Dans le cas PPOSR on récupère le ActiveTab et le FkIdWorkflowGroupe sur le bouton
                    if (activeTabVal) {
                        $me.find('input[name="ActiveTab"]').val($btn.data('activetab'));
                    }

                    if (wfGroupVal) {
                        $me.find('input[name="FkIdWorkflowGroupe"]').val($btn.data('id-workflowgroupe'));
                    }

                    $me.modal('show');
                });

            //active la modal bootstrap
            $me.modal({
                backdrop: true,
                show: false
            });

            //click sur annuler
            $me.find('.echange-info-cancel').click(function (e) {
                e.preventDefault();
                $me.modal('hide');
            });

            $me.echangeInformationFiles();

            var $searchIntervenant = $me.find('.searchIntervenantPointArret'),
                $forceDest = $me.find('input[name="ForcedDestinataire"]'),
                $dests = $me.find('.foundDests');

            $searchIntervenant.pointArretRechercheIntervenant(function (search) {
                var sData = {};

                $.each($f.serializeArray(), function (ix, v) {
                    sData[v.name] = v.value;
                });

                sData['search'] = search;

                return $.fn.putValidationToken(sData);

            }, function (data) {
                if (!data) {
                    return;
                }

                $forceDest.val(data.id);
                $dests.text(data.mail);
            });

            // Dans certains cas ce ne sera pas bindé au click sur le bouton ("binder modale", voir plus haut) ; ex : accord/observations
            $.fn.bindClassicClick($f, $me, $btSave);

            //spécial accord observation
            $me.find('input[type=radio][name=Accord]').click(function () {
                var txt = $me.find("textarea.accobs-observations"),
                    date = $me.find("input[type=text].accobs-dateretour");

                //on active le bouton "enregistrer"
                $btSave.removeAttr("disabled", "disabled");

                if ($(this).val() === '1') {
                    $me.find('.accobs-reponsedep').slideUp('fast', function () {
                        // Pour shunter la validation
                        txt.removeClass("required");
                        date.removeClass('required');
                        if (txt.val() === "") {
                            txt.val("vide");
                        }
                    });

                } else {
                    // Pour shunter la validation
                    txt.addClass("required");
                    date.addClass('required');
                    if (txt.val() === "vide") {
                        txt.val("");
                    }
                    $me.find('.accobs-reponsedep').slideDown('fast');
                }
            });


            if ($dests.length > 0) {
                var foundDests = {
                    contact: null,
                    pointArret: null
                };

                var doLookup = function (destProp) {
                    return $.ajax({
                        url: '/Affaire/LookupPointArret',
                        type: 'post',
                        data: $me.find('form').serialize()
                    }).done(function (data) {
                        foundDests[destProp] = data;
                        putDest(destProp);
                    });
                };

                var putDest = function (param) {
                    var destProp = "pointArret";

                    if (foundDests[destProp]) {
                        $dests.text(foundDests[destProp].join(', '));
                    } else {
                        doLookup(destProp);
                    }
                };

                // TM 31/05/2018 : 'show' ne marche pas avec BS3, je remplace par 'shown.bs.modal' (fonctionne aussi avec l'ancien BS)
                $me.on('shown.bs.modal', function () {
                    $searchIntervenant.select2('val', '');
                    $forceDest.val('');
                    putDest();
                });
            }

            var $reaf = $me.find('.reaffecteContact');

            if ($reaf.length > 0) {
                $reaf.contactReaffectation(function (search) {
                    return $.fn.putValidationToken({
                        term: search
                    });
                }, function () {
                });
            }
        });
    };

    (function () {
        $.fn.rechercheIntervenantBase = function(getData, onChange, url) {
            var $this = $(this);

            if (typeof onChange !== "function" || typeof getData !== "function") {
                return $this;
            }

            return $this.each(function() {
                var $me = $(this);

                $me.select2({
                    placeholder: "Choisissez un utilisateur",
                    multiple: false,
                    width: "element",
                    minimumInputLength: 2,
                    formatNoMatches: function() {
                        return "Aucun résultat";
                    },
                    formatInputTooShort: function(input, min) {
                        return "Veuillez saisir au moins " + (min - input.length) + " caractères";
                    },
                    formatSearching: function() {
                        return "Recherche...";
                    },
                    ajax: {
                        url: url,
                        type: 'POST',
                        data: function(term, page) {
                            return getData(term, page);
                        },
                        results: function(data) {
                            return { results: data };
                        }
                    },
                    formatResult: function(item) {
                        return item.label;
                    },
                    formatSelection: function(item) {
                        return item.label;
                    },
                    dropdownCssClass: "bigdrop"
                });

                $me.on('change',
                    function() {
                        onChange($(this).select2('data'));
                        
                        let $submitButton = $(this).next("[type=submit]");
                        if($submitButton.length){
                            $submitButton.removeAttr("disabled");
                        }
                    });
            });
        };

        $.fn.pointArretRechercheIntervenant = function(getData, onChange) {
            return $(this).rechercheIntervenantBase(getData, onChange, '/Affaire/PointArretRechercheIntervenant');
        };

        $.fn.contactReaffectation = function(getData, onChange) {
            return $(this).rechercheIntervenantBase(getData, onChange, '/IntervenantSearch/Recherche');
        };

        $("input.commentaire, textarea.commentaire").change(function() { validateCommentaire($(this)); });
    })();
})(jQuery);
