﻿import { FicheComptageUtils } from "./pposr-fiche-comptage-utils";
import { LibelleValue, FicheComptageIndexViewModel } from "./pposr-fiche-comptage-base";

// ReSharper disable InconsistentNaming
export class Compteur {
    _isBp = ko.observable<boolean>();
    IsPresent = ko.observable<boolean>();
    Matricule = ko.observable<string>();
    Identifiant = ko.observable<string>();
    IdentifiantCle = ko.observable<string>();
    Technologie = ko.observable<string>();
    Constructeur = ko.observable<string>();
    _ConstructeurArray = ko.observableArray<LibelleValue>();
    OptionTarifaire = ko.observable<string>();
    GrilleDistributeur = ko.observable<string>();
    TypeCompteur = ko.observable<number>();
    _TypeCompteurArray = ko.observableArray<LibelleValue>();
    Index = ko.observable<FicheComptageIndexViewModel>();

    constructor(isBp: boolean) {
        this._isBp(isBp);
    }

    /*Zone des "is" something*/


    _compteurIsTarif(forTarif: string): boolean {
        const techno = this.Technologie();
        if (techno.toString() === FicheComptageUtils.reglesGestion.forTypeTechnoLinky)
            return false;

        const typeOptionTarifaire = this.OptionTarifaire();

        if (typeOptionTarifaire == null) {
            return false;
        }

        return forTarif === typeOptionTarifaire.toString();
    }

    _compteurIsGrilleDistributeur(forGrille: string): boolean {
        const techno = this.Technologie();
        if (!techno || techno.toString() !== FicheComptageUtils.reglesGestion.forTypeTechnoLinky)
            return false;

        const grilleDistributeur = this.GrilleDistributeur();

        if (grilleDistributeur == null) {
            return false;
        }

        return forGrille === grilleDistributeur.toString();
    }

    _displayIndex = ko.pureComputed({
        owner: this,
        read: () => {
            if (this.Technologie() == FicheComptageUtils.reglesGestion.forTypeTechnoLinky)
                return this.GrilleDistributeur();
            return this.OptionTarifaire();
        }
    });

    _isCbe = ko.pureComputed({
        owner: this,
        read: () => {
            return FicheComptageUtils.reglesGestion.forTypeTechnoCbe === this.Technologie().toString();
        }
    });

    _isLinky = ko.pureComputed({
        owner: this,
        read: () => {
            return FicheComptageUtils.reglesGestion.forTypeTechnoLinky === this.Technologie().toString();
        }
    });

    _isCem = ko.pureComputed({
        owner: this,
        read: () => {
            return FicheComptageUtils.reglesGestion.forTypeTechnoCem === this.Technologie().toString();
        }
    });

    /*Zone des "init" something*/
    _setConstructeur() {
        const dataTypeCompteur = FicheComptageUtils.getTypeCompteurData(this.TypeCompteur());
        this.Constructeur(dataTypeCompteur.constructeurNom);
    }

    _initListTypeCompteur(nbPole: string) {
        // En Linky ou CBE, on définit le constructeur automatiquement : les deux premiers chars de l'identifiant
        // et on peuple directement la liste des types compteurs
        let codeConstructeur = "";
        if (!this._isCem() && FicheComptageUtils.identifiantIsValid(this.Identifiant())) {
            codeConstructeur = this.Identifiant().substr(0, 2);
        }

        const data = {
            codeConstructeur: codeConstructeur,
            technoCompteur: this.Technologie(),
            nbPole: nbPole
        };

        FicheComptageUtils.initArray(this._TypeCompteurArray, "/FicheComptage/GetListTypeCompteur", data);
    }


    _compteurIsTarifComplexe(): boolean {
        return this._compteurIsTarif(FicheComptageUtils.reglesGestion.forDoubleTarif) ||
            this._compteurIsTarif(FicheComptageUtils.reglesGestion.forTempo) ||
            this._compteurIsTarif(FicheComptageUtils.reglesGestion.forEJP);
    }

    private _labelBleuHp() {
        if (this._isLinky()) {
            if (this._compteurIsGrilleDistributeur(FicheComptageUtils.reglesGestion.forGrilleDistributeurDouble)) {
                return FicheComptageUtils.reglesGestion.forLabelBleuHpLinkyDouble;
            }
            if (this._compteurIsGrilleDistributeur(FicheComptageUtils.reglesGestion.forGrilleDistributeur4Postes)) {
                return FicheComptageUtils.reglesGestion.forLabelBleuHpLinky4Postes;
            }
            return FicheComptageUtils.reglesGestion.forLabelBleuHpLinkyBase;

        } else {
            if (this._compteurIsTarif(FicheComptageUtils.reglesGestion.forDoubleTarif)) {
                return FicheComptageUtils.reglesGestion.forLabelBleuHpDoubleTarif;
            }
            if (this._compteurIsTarif(FicheComptageUtils.reglesGestion.forTempo) ||
                this._compteurIsTarif(FicheComptageUtils.reglesGestion.forEJP)) {
                return FicheComptageUtils.reglesGestion.forLabelBleuHpTempo;
            }

            return FicheComptageUtils.reglesGestion.forLabelBleuHpSimpleTarif;
        }
    }

    private _labelBleuHc() {
        if (this._isLinky()) {
            if (this._compteurIsGrilleDistributeur(FicheComptageUtils.reglesGestion.forGrilleDistributeur4Postes)) {
                return FicheComptageUtils.reglesGestion.forLabelBleuHcLinky4Postes;
            }
            return FicheComptageUtils.reglesGestion.forLabelBleuHcLinkyDouble;
        } else {
            if (this._compteurIsTarif(FicheComptageUtils.reglesGestion.forTempo) ||
                this._compteurIsTarif(FicheComptageUtils.reglesGestion.forEJP)) {
                return FicheComptageUtils.reglesGestion.forLabelBleuHcTempo;
            }

            return FicheComptageUtils.reglesGestion.forLabelBleuHcDoubleTarif;
        }
    }

    LabelBleuHp = ko.pureComputed({
        owner: this,
        read: () => {
            return this._labelBleuHp();
        }
    });

    LabelBleuHc = ko.pureComputed({
        owner: this,
        read: () => {
            return this._labelBleuHc();
        }
    });

    LabelBlancHp = ko.pureComputed({
        owner: this,
        read: () => {
            if (this._isLinky())
                return FicheComptageUtils.reglesGestion.forLabelBlancHpLinky4Postes;
            else
                return FicheComptageUtils.reglesGestion.forLabelBlancHp;
        }
    });

    LabelBlancHc = ko.pureComputed({
        owner: this,
        read: () => {
            if (this._isLinky())
                return FicheComptageUtils.reglesGestion.forLabelBlancHcLinky4Postes;
            else
                return FicheComptageUtils.reglesGestion.forLabelBlancHc;
        }
    });

    LabelRougeHp = ko.pureComputed({
        owner: this,
        read: () => {
            if (this._isLinky())
                return "";
            else
                return FicheComptageUtils.reglesGestion.forLabelRougeHp;
        }
    });

    LabelRougeHc = ko.pureComputed({
        owner: this,
        read: () => {
            if (this._isLinky())
                return "";
            else
                return FicheComptageUtils.reglesGestion.forLabelRougeHc;
        }
    });

    _displayBleuHc = ko.pureComputed({
        owner: this,
        read: () => {
            if (this._isLinky()) {
                return this._compteurIsGrilleDistributeur(FicheComptageUtils.reglesGestion
                    .forGrilleDistributeurDouble) ||
                    this._compteurIsGrilleDistributeur(
                        FicheComptageUtils.reglesGestion.forGrilleDistributeur4Postes);
            } else {
                return this._compteurIsTarif(FicheComptageUtils.reglesGestion.forDoubleTarif) ||
                    this._compteurIsTarif(FicheComptageUtils.reglesGestion.forTempo) ||
                    this._compteurIsTarif(FicheComptageUtils.reglesGestion.forEJP);
            }
        }
    });


    _displayBlanc = ko.pureComputed({
        owner: this,
        read: () => {
            if (this._isLinky()) {
                return this._compteurIsGrilleDistributeur(FicheComptageUtils.reglesGestion
                    .forGrilleDistributeur4Postes);
            } else {
                return !this._isBp() && (this._compteurIsTarif(FicheComptageUtils.reglesGestion.forTempo) ||
                    this._compteurIsTarif(FicheComptageUtils.reglesGestion.forEJP));
            }
        }
    });

    _displayRouge = ko.pureComputed({
        owner: this,
        read: () => {
            if (this._isLinky()) {
                return false;
            } else {
                return !this._isBp() && (this._compteurIsTarif(FicheComptageUtils.reglesGestion.forTempo) ||
                    this._compteurIsTarif(FicheComptageUtils.reglesGestion.forEJP));
            }
        }
    });
}
