﻿import { PlanPrevention } from "./PlanPrevention";
import { MiniFile } from "./MiniFile";
import { Intervenant } from "./Intervenant";
import { ContactUrgence } from "./ContactUrgence";
import { Risque } from "./Risque";
import { PhasePlanning } from "./PhasePlanning";
import { Utils } from "../typings/Utils";

export var regexTel = /^(\+|00( )*)*[0-9 ]{2,4}[ .-]*([0-9]{2}[ .-]*){4}$/;
export var regexMail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;

export class InspectionCommunePrealable extends PlanPrevention {
    FkIdPlanPrevention = ko.observable<number>();
    DateDebutTravaux = ko.observable<string>();
    DateFinTravaux = ko.observable<string>();
    Modified = ko.observable<Date>();
    Created = ko.observable<Date>();
    Author = ko.observable<string>();
    ConsistanceTravaux = ko.observable<string>();
    AutresContraintes = ko.observable<string>();

    ConsistanceFiles = ko.observableArray<MiniFile>();
    AutresContraintesFiles = ko.observableArray<MiniFile>();
    CdaEtudeChecked = ko.observable<Boolean>();
    CdaTravauxChecked = ko.observable<Boolean>();
    DossierArticleR32325Checked = ko.observable<Boolean>();
    DceChecked = ko.observable<Boolean>();
    AutreChecked = ko.observable<Boolean>();
    AutreSaisie = ko.observable<string>();
    DateICP = ko.observable<Date>().extend({ required: "" });
    InstructionsSpecifiquesDesEE = ko.observable<string>();
    EnedisCEDA = ko.observable<string>();
    EnedisMOAR = ko.observable<string>();

    IsPlanningDisplayed = ko.observable<Boolean>(false);

    _ListIntervenantAutre = ko.observableArray<Intervenant>();
    _ListIntervenantEntrepriseUtilisatrice = ko.observableArray<Intervenant>();
    _ListIntervenantEntrepriseUtilisatriceCEDA = ko.observableArray<Intervenant>();
    _ListIntervenantEntrepriseExterieure = ko.observableArray<Intervenant>();
    _ListIntervenantReprésentantCHSCT = ko.observableArray<Intervenant>();
    _ListIntervenantSignataire = ko.observableArray<Intervenant>();

    _IntervenantTemporaireEntrepriseUtilisatrice = ko.observable<Intervenant>(new Intervenant(1));
    _IntervenantTemporaireEntrepriseUtilisatriceCEDA = ko.observable<Intervenant>(new Intervenant(6));
    _IntervenantTemporaireEntrepriseExterieure = ko.observable<Intervenant>(new Intervenant(2));
    _IntervenantTemporaireReprésentantCHSCT = ko.observable<Intervenant>(new Intervenant(3));
    _IntervenantTemporaireAutre = ko.observable<Intervenant>(new Intervenant(4));
    _IntervenantTemporaireSignataire = ko.observable<Intervenant>(new Intervenant(7));

    ListTelephonesUtilesUrgence = ko.observableArray<ContactUrgence>();
    TelephoneVideUrgence = ko.observable<ContactUrgence>(new ContactUrgence(1));
    ListTelephonesExploitationsEnedis = ko.observableArray<ContactUrgence>();
    TelephoneVideExploitation = ko.observable<ContactUrgence>(new ContactUrgence(2));

    constructor(data) {
        super(data, true);
        if (!data) {
            return;
        }
        this.FkIdPlanPrevention(data.FkIdPlanPrevention);
        this.DateDebutTravaux(data.DateDebutTravaux);
        this.DateFinTravaux(data.DateFinTravaux);
        this.ConsistanceTravaux(data.ConsistanceTravaux);
        this.AutresContraintes(data.AutresContraintes);

        //if (!this.IsBrouillon()) {
        //    this._UpdatePartial();
        //}

        this.CdaEtudeChecked(data.CdaEtudeChecked);
        this.CdaTravauxChecked(data.CdaTravauxChecked);
        this.DossierArticleR32325Checked(data.DossierArticleR32325Checked);
        this.DceChecked(data.DceChecked);
        this.AutreChecked(data.AutreChecked);
        this.AutreSaisie(data.AutreSaisie);
        this.InstructionsSpecifiquesDesEE(data.InstructionsSpecifiquesDesEE);
        this.EnedisCEDA(data.EnedisCEDA);
        this.EnedisMOAR(data.EnedisMOAR);
        this.DateICP(data.DateICP);
        this.Modified(data.Modified);
        this.Created(data.Created);
        this.Author(data.Author);
        let listIdSignataires: string[] = [];
        let listIdEU: string[] = [];
        for (const index in data.IntervenantHasInspectionCommunePrealableKo) {
            if (Object.prototype.hasOwnProperty.call(data.IntervenantHasInspectionCommunePrealableKo, index)) {
                const interv = new Intervenant(data.IntervenantHasInspectionCommunePrealableKo[index]);
                switch (interv.TypePresence()) {
                case 1:
                    this._ListIntervenantEntrepriseUtilisatrice().push(interv);
                    listIdEU.push(interv.FkIdIntervenant());
                    break;
                case 2:
                    this._ListIntervenantEntrepriseExterieure().push(interv);
                    if (interv.IsTemporaire() == true) {
                        interv.WantToBeSignataire(true);
                        //this._SignataireChanged(interv);
                    }
                    break;
                case 3:
                    this._ListIntervenantReprésentantCHSCT().push(interv);
                    break;
                case 6:
                    this._ListIntervenantEntrepriseUtilisatriceCEDA().push(interv);
                    break;
                case 7:
                    this._ListIntervenantSignataire().push(interv);
                    listIdSignataires.push(interv.FkIdIntervenant());
                    break;
                default:
                    this._ListIntervenantAutre().push(interv);
                    break;
                }
            }
        }
        //on set les _CanEditPourOrdre si les signataires sont dans la liste EU
        //on set les WantToBeSignataire si les intervenants sont dans la liste des signataires
        const listCommune = listIdSignataires.filter(_ => listIdEU.some(eu => eu == _));
        this._ListIntervenantSignataire().filter(_ => listCommune.some(com => com == _.FkIdIntervenant())).forEach(_ => _._CanEditPourOrdre(true));
        this._allIntervenant().filter(_ => listIdSignataires.some(com => com == _.FkIdIntervenant())).forEach(_ => _.WantToBeSignataire(true));

        for (const index in data.RisqueInspectionCommunePrealableKo) {
            if (Object.prototype.hasOwnProperty.call(data.RisqueInspectionCommunePrealableKo, index)) {
                this._Risques().push(new Risque(data.RisqueInspectionCommunePrealableKo[index]));
            }
        }
        for (const index in data.ListTelephonesExploitationsEnedis) {
            if (Object.prototype.hasOwnProperty.call(data.ListTelephonesExploitationsEnedis, index)) {
                this.ListTelephonesExploitationsEnedis()
                    .push(new ContactUrgence(data.ListTelephonesExploitationsEnedis[index]));
            }
        }
        for (const index in data.ListTelephonesUtilesUrgence) {
            if (Object.prototype.hasOwnProperty.call(data.ListTelephonesUtilesUrgence, index)) {
                this.ListTelephonesUtilesUrgence().push(new ContactUrgence(data.ListTelephonesUtilesUrgence[index]));
            }
        }
        for (const index in data.AutresContraintesFiles) {
            if (Object.prototype.hasOwnProperty.call(data.AutresContraintesFiles, index)) {
                const file = new MiniFile(data.AutresContraintesFiles[index]);
                this.AutresContraintesFiles().push(file);
            }
        }
        for (const index in data.ConsistanceFiles) {
            if (Object.prototype.hasOwnProperty.call(data.ConsistanceFiles, index)) {
                this.ConsistanceFiles().push(new MiniFile(data.ConsistanceFiles[index]));
            }
        }
    }

    _allIntervenant = ko.computed({
        owner: this,
        pure: true,
        read: () => {
            let allInterv = new Array<Intervenant>();
            let interv: Intervenant;
            const listIntervenantAutre = this._ListIntervenantAutre();
            for (const index in listIntervenantAutre) {
                if (Object.prototype.hasOwnProperty.call(listIntervenantAutre, index)) {
                    interv = listIntervenantAutre[index];
                    if (!interv.IsTemporaire()) {
                        allInterv.push(interv);
                    }
                }
            }
            const listIntervenantEntrepriseUtilisatrice = this._ListIntervenantEntrepriseUtilisatrice();
            for (const index in listIntervenantEntrepriseUtilisatrice) {
                if (Object.prototype.hasOwnProperty.call(listIntervenantEntrepriseUtilisatrice, index)) {
                    interv = listIntervenantEntrepriseUtilisatrice[index];
                    if (!interv.IsTemporaire()) {
                        allInterv.push(interv);
                    }
                }
            }
            const listIntervenantEntrepriseUtilisatriceCeda = this._ListIntervenantEntrepriseUtilisatriceCEDA();
            for (const index in listIntervenantEntrepriseUtilisatriceCeda) {
                if (Object.prototype.hasOwnProperty.call(listIntervenantEntrepriseUtilisatriceCeda, index)) {
                    interv = listIntervenantEntrepriseUtilisatriceCeda[index];
                    if (!interv.IsTemporaire()) {
                        allInterv.push(interv);
                    }
                }
            }
            const listIntervenantEntrepriseExterieure = this._ListIntervenantEntrepriseExterieure();
            for (const index in listIntervenantEntrepriseExterieure) {
                if (Object.prototype.hasOwnProperty.call(listIntervenantEntrepriseExterieure, index)) {
                    interv = listIntervenantEntrepriseExterieure[index];
                    if (!interv.IsTemporaire()) {
                        allInterv.push(interv);
                    }
                }
            }
            const listIntervenantReprésentantChsct = this._ListIntervenantReprésentantCHSCT();
            for (const index in listIntervenantReprésentantChsct) {
                if (Object.prototype.hasOwnProperty.call(listIntervenantReprésentantChsct, index)) {
                    interv = listIntervenantReprésentantChsct[index];
                    if (!interv.IsTemporaire()) {
                        allInterv.push(interv);
                    }
                }
            }
            const listIntervenantSignataire = this._ListIntervenantSignataire();
            for (const index in listIntervenantSignataire) {
                if (Object.prototype.hasOwnProperty.call(listIntervenantSignataire, index)) {
                    interv = listIntervenantSignataire[index];
                    if (!interv.IsTemporaire()) {
                        allInterv.push(interv);
                    }
                }
            }
            return allInterv;
        }
    });

    _IsValid = ko.computed({
        owner: this,
        pure: true,
        read: () => {
            return !this.DateICP.hasError();
        }
    });

    _addRisque(risque: Risque) {
        const newRisque = new Risque(risque, true);
        newRisque.IsTemporaire(false);
        this._Risques.push(newRisque);
        risque.raz(); //On remet le risque temporaire pour la vue
    }

    _allIntervenantNameValide = ko.computed({ //Sans les signataires
        owner: this, pure: true, read: () => {
            let allNames = new Array<Object>();
            let interv: Intervenant;
            let filter = this._allIntervenant().filter(_ => _.TypePresence() !== 7);
            for (const index in filter) {
                if (Object.prototype.hasOwnProperty.call(this._allIntervenant().filter(_ => _.TypePresence() !== 7),
                    index)) {
                    interv = this._allIntervenant()[index];
                    allNames.push({ id: interv.NomPrenomLibelle(), text: interv.NomPrenomLibelle() });
                }
            }
            return ko.toJSON(allNames);
        }
    });

    _phasesAvailables = ko.computed({
        owner: this, pure: true, read: () => {
            let options = Array<PhasePlanning>();
            const listIntervenantEntrepriseExterieure = this._ListIntervenantEntrepriseExterieure();
            for (const indexOfInterv in listIntervenantEntrepriseExterieure) {
                if (Object.prototype.hasOwnProperty.call(listIntervenantEntrepriseExterieure, indexOfInterv)) {
                    const listPhases = listIntervenantEntrepriseExterieure[indexOfInterv].ListPhases();
                    for (const indexOfLib in listPhases) {
                        if (Object.prototype.hasOwnProperty.call(
                            listPhases,
                            indexOfLib)) {
                            options.push(
                                listPhases[indexOfLib]);
                        }
                    }
                }
            }
            return options;
        }
    });

    _allPhases = ko.computed({
        owner: this, pure: true, read: () => {
            let listPhase = new Array<PhasePlanning>();
            const allIntervenant = this._allIntervenant();
            for (const index in allIntervenant) {
                if (Object.prototype.hasOwnProperty.call(allIntervenant, index)) {
                    const listPhases = allIntervenant[index].ListPhases();
                    for (const i in listPhases) {
                        if (Object.prototype.hasOwnProperty.call(listPhases, i)) {
                            listPhase.push(listPhases[i]);
                        }
                    }
                }
            }
            return listPhase;
        }
    });

    _UpdatePartial() {
        this._save(true, false, true).then(() => {
            $.ajax({
                url: '/Affaire/GetPlanningPartial/',
                type: 'POST',
                data: {
                    __RequestVerificationToken: $.fn.findValidationToken(),
                    id: this.Id()
                }
            })
                .done((data: any) => {
                    const planning = $(`#planning${this.Id()}`);
                    planning.html(data);
                });
        });
    }

    _removeContact(contant: ContactUrgence, listConcerned: KnockoutObservableArray<ContactUrgence>) {
        listConcerned.remove(contant);
    }

    _addContact(contact: ContactUrgence, listConcerned: KnockoutObservableArray<ContactUrgence>) {
        listConcerned.push(new ContactUrgence(contact, true));
        contact.raz();//Pour remettre a zero les champs d'ajout
    }

    addIntervenant(intervenant: Intervenant, listConcerned: KnockoutObservableArray<Intervenant>) {
        const newInterv = new Intervenant(intervenant, true);
        if (newInterv.TypePresence() == 2) { //EE
            newInterv.WantToBeSignataire(true);
            this._SignataireChanged(newInterv);
        }
        listConcerned.push(newInterv);
        intervenant.raz();//Pour remettre a zero les champs d'ajout
    }

    _SignataireChanged(intervenant: Intervenant) {
        const intervConcerned = this._ListIntervenantSignataire().filter(_ => _.FkIdIntervenant() == intervenant.FkIdIntervenant());
        if (intervenant.WantToBeSignataire()) { //On l'ajoute à la liste des signataires
            if (intervConcerned.length == 0) {
                const newIntervenant = new Intervenant(intervenant, true);
                newIntervenant.IsChargeDeLaSecurite(false); //On lui enlève ça cependant, sinon ça fait doublon
                if (newIntervenant.TypePresence() == 1) {
                    newIntervenant._CanEditPourOrdre(true);
                }
                newIntervenant.TypePresence(7);
                this._ListIntervenantSignataire.push(newIntervenant);
            }
        } else {
            if (intervConcerned.length > 0) {
                this.removeIntervenant(intervConcerned[0], this._ListIntervenantSignataire);
            }
        }
        return true;
    }

    _ValidIntervenantEE(intervenant: Intervenant) {
        intervenant.PhaseTemporaire(new PhasePlanning(intervenant.NbSalariesAffectes(), intervenant));
        intervenant.IsTemporaire(false);
        this._SignataireChanged(intervenant);
    }

    _PourOrdreChanged(intervenant: Intervenant) { //On chope le signataire et on le met en pour ordre
        const intervToModify = this._ListIntervenantSignataire().filter(_ => _.FkIdIntervenant() == intervenant.FkIdIntervenant());
        if (intervToModify.length > 0) {
            intervToModify[0].IsPourOrdre(intervenant.IsPourOrdre());
        }
        return true;
    }

    //Juste un alias pour le binding
    IntervenantHasInspectionCommunePrealableKo = ko.computed({
        owner: this, pure: true, read: () => {
            return this._allIntervenant();
        }
    });

    _save(brouillon: boolean, fromVisualiser?: boolean, fromGenererPlanning?: boolean) {
        $.sticky("Sauvegarde en cours...", { cssClass: '' });

        this.IsBrouillon(brouillon);

        const needasync = fromVisualiser ? false : true;

        return $.ajax({
            url: '/Affaire/SaveICP',
            type: 'POST',
            //Pour que le target _blank fonctionne faut que ça soit un trusted event et pour ce faire il faut le async false et gérer l'ouverture de la tab dans le success() et non le done()
            async: needasync,
            success: () => {
                if (fromVisualiser) {
                    const url = `/Affaire/DisplayICP/${this.Id()}`;
                    window.open(url, '_blank');
                }
            },
            data: {
                __RequestVerificationToken: $.fn.findValidationToken(),
                icp: ko.toJSON(this, Utils.cleanPrivateProps),
                risques: ko.toJSON(this._allRisquesValid())
            }
        })
            //Si on vient de visualiser, on ne fait rien, c'est fait dans le success
            .done((data) => {
                if (!fromGenererPlanning) {
                    if (!fromVisualiser) {
                        location.reload();
                    }
                }
            });
    }
}

export var instance: InspectionCommunePrealable;
